import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  MenuItem, SubMenu,
} from 'react-pro-sidebar';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { GlobalContext } from '../../../../context/GlobalContext';
import Link from '../../link/InternalLink';
import './ReviewLinks.scss';
import { get } from '../../../../lib/strings';
import PermissionsGated from '../../../context-aware/PermissionsGated/PermissionsGated';
import Permissions from '../../../../lib/permissions';
import RouteSideBarMapping from '../../../../lib/consts/routeSideBarMapping';

const ReviewLinks = ({
  styleForSelectedItem, toggleSubMenu, subMenuState, handleToggleSidebar,
}) => {
  const globalContext = useContext(GlobalContext);
  const {
    reviewLinks, availableYearsForAnnualData,
  } = globalContext;

  const [subMenuOpenIndex, updateSubMenuOpenIndex] = useState([]);
  // Used to handle best practices sub section menu opened by default
  const [isBestPracticesSubSectionsDefaultOpen,
    setIsBestPracticesSubSectionsDefaultOpen] = useState(true);
  const { hash } = useLocation();
  const locationObj = useLocation();

  const pathArray = locationObj.pathname.toString().split('/');
  const { pathname } = locationObj;

  const getSelectedYearForAnnualData = () => {
    // grab selected year from context: 1not selected yet , as default fitst year;
    const isYearNotSelected = _.every(availableYearsForAnnualData, { selected: false });
    return (isYearNotSelected && availableYearsForAnnualData.length)
      ? availableYearsForAnnualData[availableYearsForAnnualData.length - 1].value
      : availableYearsForAnnualData.filter((_item) => _item.selected === true)[0].value;
  };

  const sideBarMenuBestPraticesTitle = get('sidebar-BestPractices');
  const sideBarMenuAnnualDataTitle = get('sidebar-AnnualData');
  const sideBarMenuAnnualDataSlug = 'annual-data';
  const sideBarMenuBestPraticesSlug = 'best-practice';

  const currentSlugInUrl = pathArray[1];
  const currentSelectedYear = pathArray[3];

  const defaultYearForAnnualData = currentSelectedYear || (availableYearsForAnnualData
  && availableYearsForAnnualData.length !== 0
    ? getSelectedYearForAnnualData() : undefined);
  // check we have some items to render 1 check parent menu open status' 2 check current url slug
  const initialAnnualRiviewMenuSatate = subMenuState[RouteSideBarMapping.BEST_PRACTICES.INDEX] ? currentSlugInUrl === 'annual-review' : false;
  // false:close
  const [itemMenuState, setItemMenuState] = React.useState([false, initialAnnualRiviewMenuSatate]);

  const toggleSubItemMenu = (i, isToggle = true) => {
    const clone = itemMenuState.slice(0);
    // Reset all sub-menus except for the one that clicked
    const newState = clone.map((val, index) => {
      if (index === i) {
        return val;
      }
      return false;
    });
    // if isToggle=false, just open submenu; otherwise reverse preState;
    newState[i] = isToggle ? !newState[i] : true;

    setItemMenuState(newState);
  };

  const isSectionSelected = (slug, path) => hash === `#${slug}` || path.indexOf(`/${currentSlugInUrl}/${sideBarMenuBestPraticesSlug}/${slug}/`) !== -1;

  useEffect(() => {
    if (!pathname) return;
    if (pathname.includes(`${RouteSideBarMapping.BEST_PRACTICES.LINK}/${sideBarMenuBestPraticesSlug}`)) { // TOGGLE CONTROL
      toggleSubItemMenu(0, false);
    }
    if (pathname.includes(RouteSideBarMapping.ANNUAL_REVIEW.LINK)
    && availableYearsForAnnualData.length) {
      toggleSubItemMenu(1, false);
    }
  }, [pathname, availableYearsForAnnualData]);

  // Handle review best pratices menu state
  useEffect(() => {
    if (!pathname) return;
    if (!reviewLinks || !reviewLinks.bestPractices) return;

    if (pathname.indexOf(`/review/${sideBarMenuBestPraticesSlug}`) === -1) {
      const ids = reviewLinks.bestPractices.map((item, index) => index);
      updateSubMenuOpenIndex(ids);
    }

    // Handle best practices sub section
    if (pathname === `/review/${sideBarMenuBestPraticesSlug}`) {
      setIsBestPracticesSubSectionsDefaultOpen(true);
    } else {
      setIsBestPracticesSubSectionsDefaultOpen(false);
    }

    if (hash) {
      const hasName = hash.substring(1, hash.length);
      if (reviewLinks.bestPractices) {
        const menuIndex = reviewLinks.bestPractices.findIndex((item) => item.slug === hasName);
        if (menuIndex !== -1) {
          updateSubMenuOpenIndex([menuIndex]);
        }
      }
    }
  }, [pathname, hash]);

  useEffect(() => {
    if (reviewLinks && reviewLinks.bestPractices) {
      const ids = reviewLinks.bestPractices.map((item, index) => index);
      updateSubMenuOpenIndex(ids);
    }
  }, [reviewLinks]);

  return (
    <PermissionsGated activities={[Permissions.activities.REVIEWS]}>
      <SubMenu
        className="review-menu"
        title={(
          <Link to={`/review/${sideBarMenuBestPraticesSlug}`}>
            {get('sidebar-Review')}
          </Link>
        )}
        onOpenChange={() => {
          setIsBestPracticesSubSectionsDefaultOpen(true);
          toggleSubMenu(RouteSideBarMapping.BEST_PRACTICES.INDEX);
        }}
        open={subMenuState[RouteSideBarMapping.BEST_PRACTICES.INDEX]}
      >
        <SubMenu
          className={styleForSelectedItem(`/review/${sideBarMenuBestPraticesSlug}`, 'menu')}
          title={<Link to={`/review/${sideBarMenuBestPraticesSlug}`}>{sideBarMenuBestPraticesTitle}</Link>}
          onOpenChange={() => {
            setIsBestPracticesSubSectionsDefaultOpen(true);
            if (reviewLinks && reviewLinks.bestPractices) {
              const ids = reviewLinks.bestPractices.map((item, index) => index);
              if (subMenuOpenIndex.length !== ids.length) {
                updateSubMenuOpenIndex(ids);
              }
            }

            toggleSubItemMenu(0);
          }}
          open={itemMenuState[0]}
          onClick={() => setIsBestPracticesSubSectionsDefaultOpen(false)}

        >
          {
            reviewLinks
            && reviewLinks.bestPractices
            && reviewLinks.bestPractices.map((questionData, index) => {
              return (
                <SubMenu
                  defaultOpen
                  className={`dynamic-links__subsection-link ${(hash === `#${questionData.slug.toLowerCase()}`) ? 'active' : ''}`}
                  key={questionData.id}
                  prefix={(
                    <p className="subsection-indicator">{`${isSectionSelected(questionData.slug, pathname) || isBestPracticesSubSectionsDefaultOpen ? '-' : '+'}`}</p>
                  )}
                  title={(
                    <Link
                      to={`/review/${sideBarMenuBestPraticesSlug}/summary#${questionData.slug}`}
                      className={`${isSectionSelected(questionData.slug, pathname) ? 'active' : ''}`}
                    >
                      {`${questionData.title}`}
                    </Link>
                  )}
                  onClick={() => setIsBestPracticesSubSectionsDefaultOpen(false)}
                  open={isBestPracticesSubSectionsDefaultOpen ? true : (hash === `#${questionData.slug}`) || pathname.indexOf(`/review/${sideBarMenuBestPraticesSlug}/${questionData.slug}/`) !== -1}
                  onOpenChange={() => {
                    if (!subMenuOpenIndex.includes(index)) {
                      subMenuOpenIndex.push(index);
                    } else {
                      const idx = subMenuOpenIndex.indexOf(index);
                      if (idx !== -1) {
                        subMenuOpenIndex.splice(idx, 1);
                      }
                      updateSubMenuOpenIndex(subMenuOpenIndex);
                    }
                  }}
                >

                  {questionData && questionData.sections && questionData.sections.map((section) => {
                    return (
                      <MenuItem
                        className="sub-navigation-menu"
                        key={section.id}
                        onClick={() => {
                          handleToggleSidebar();
                          setIsBestPracticesSubSectionsDefaultOpen(false);
                        }}
                      >
                        <Link
                          className={pathname.indexOf(`/review/${sideBarMenuBestPraticesSlug}/${questionData.slug}/${section.slug}/${section.id}`) !== -1 ? 'active' : ''}
                          to={`/review/${sideBarMenuBestPraticesSlug}/${questionData.slug}/${section.slug}/${section.id}`}
                        >
                          {section.title}
                        </Link>
                      </MenuItem>
                    );
                  })}
                </SubMenu>
              );
            })
          }
        </SubMenu>

        <SubMenu
          className={styleForSelectedItem(`/annual-review/${sideBarMenuAnnualDataSlug}`, 'menu')}
          title={<Link to={`/annual-review/${sideBarMenuAnnualDataSlug}`}>{sideBarMenuAnnualDataTitle}</Link>}
          onOpenChange={() => toggleSubItemMenu(1)}
          open={itemMenuState[1]}
          onClick={() => setIsBestPracticesSubSectionsDefaultOpen(false)}
        >
          {
            defaultYearForAnnualData
            && reviewLinks
            && reviewLinks.annualData
            && reviewLinks.annualData.length > 0
            && reviewLinks.annualData[0].sections.map((questionData) => {
              if (!questionData.active) { return null; }
              return (
                <MenuItem
                  key={questionData.slug}
                  onClick={() => {
                    handleToggleSidebar();
                    setIsBestPracticesSubSectionsDefaultOpen(false);
                  }}
                >
                  <Link className={(hash === `#annual_${questionData.title.toLowerCase()}`) ? 'active' : ''} to={`/annual-review/${sideBarMenuAnnualDataTitle}/${defaultYearForAnnualData}/annual/${questionData.id}#annual_${questionData.title.toLowerCase()}`}>
                    - {' '}{questionData.title}
                  </Link>
                </MenuItem>
              );
            })
          }
        </SubMenu>

      </SubMenu>
    </PermissionsGated>
  );
};

ReviewLinks.propTypes = {
  styleForSelectedItem: PropTypes.func.isRequired,
  toggleSubMenu: PropTypes.func.isRequired,
  subMenuState: PropTypes.arrayOf(PropTypes.bool).isRequired,
  handleToggleSidebar: PropTypes.func.isRequired,
};
export default ReviewLinks;
