/* eslint-disable max-len */
import _ from 'lodash';
import answerSetUtils from './answerSetUtils';
import reviewsService from '../services/reviews/reviews-service';
import questionTypes from './consts/questionTypes';
import { getUnits } from './utils';

const isEmptyValueAndUnitForTextInput = (values) => {
  if (!values.question) return;
  const hasValueItems = values.question.filter((answer, index) => {
    const answerId = (Object.keys(answer))[0];
    const previousAnswerId = values.previousDataQuestions && values.previousDataQuestions[index] && (Object.keys(values.previousDataQuestions[index]))[0];
    return previousAnswerId ? answer[answerId].value !== '' && values.previousDataQuestions[index][previousAnswerId] !== 'true' : answer[answerId].value !== '';
  });
  return hasValueItems.length === 0;
};

const isEmptyValueAndUnitForMultipleChoiceNest = (values) => {
  if (!values.question) return;
  const hasValueItems = values.question.filter((answer, index) => {
    const answerId = (Object.keys(answer))[0];
    const previousAnswerId = values.previousDataQuestions && values.previousDataQuestions[index] && (Object.keys(values.previousDataQuestions[index]))[0];

    return previousAnswerId ? answer[answerId] === true && values.previousDataQuestions[index][previousAnswerId] !== 'true' : answer[answerId] === true;
  });

  return hasValueItems.length === 0;
};

const isEmptyForMultiTypesAnswers = (values) => {
  if (!values.answers || values.answers.length === 0) return;
  const arrayObject = values.answers.filter((answer) => {
    const answerPair = Object.entries(answer)[0];
    return answerPair[1].name;
  });
  return arrayObject.map((answer) => Object.keys(answer)[0]).length === 0;
};
const getDifferentProperties = (obj1, obj2) => {
  if (_.isEmpty(obj1)) return;
  if (_.isEmpty(obj2)) return;
  return _.reduce(obj1, (result, value, key) => {
    if (_.isPlainObject(value)) {
      // eslint-disable-next-line no-param-reassign
      result[key] = getDifferentProperties(value, obj2[key]);
    } else if (!_.isEqual(value, obj2[key])) {
      // eslint-disable-next-line no-param-reassign
      result[key] = value;
    }
    return result;
  }, {});
};
// check  True answers && unit in current and original set or not
const isUpdateTrueAnswerOrNot = (question, answerData, user, currentValues, datagridRef) => {
  if (!answerData) return false;// non block
  if (!currentValues || _.isEmpty(currentValues)) return false;// non block
  const originalAnswerSet = answerSetUtils.pullOutAnswerById(question.id, answerData);
  const currentAnswers = reviewsService.shapeAnswersToPayload(
    currentValues,
    question.uiType,
  );
  const tidyUpOriginalAnswer = _.pickBy(originalAnswerSet, (value, key) => { return key.split('-').length === 5; });

  // original answer Set is none, just check current answers set empty or not
  if (!originalAnswerSet || _.isEmpty(tidyUpOriginalAnswer)) {
    if (question.uiType === questionTypes.TEXTINPUTS) {
      return !isEmptyValueAndUnitForTextInput(currentValues);
    }

    if (question.uiType === questionTypes.DATAGRID) {
      let hasChangedFields = false;
      if (datagridRef && datagridRef.current && datagridRef.current.getDatagridAnswers) {
        const answersDataGrid = datagridRef.current.getDatagridAnswers();
        hasChangedFields = answersDataGrid && answersDataGrid.isDirty ? answersDataGrid.isDirty : false;
      }

      return hasChangedFields;
    }
    if (question.uiType === questionTypes.MULTIPLECHOICE_ADDMORE
      || question.uiType === questionTypes.SINGLECHOICE
      || question.uiType === questionTypes.MULTIPLECHOICE
    ) return !isEmptyForMultiTypesAnswers(currentValues);

    if (question.uiType === questionTypes.MULTIPLECHOICE_NESTED) {
      return !isEmptyValueAndUnitForMultipleChoiceNest(currentValues);
    }
  } else { // has original answer , compare value and unit
    // length of answers changed
    let tidyUpOriginalNestedAnswer = {};
    Object.values(tidyUpOriginalAnswer).forEach((item) => {
      if (item && item.nestedValues && !_.isEmpty(item.nestedValues)) {
        Object.assign(tidyUpOriginalNestedAnswer, ...Object.values(item));
      } else {
        tidyUpOriginalNestedAnswer = tidyUpOriginalAnswer;
      }
    });
    if (Object.keys(currentAnswers).length
      !== Object.keys(tidyUpOriginalNestedAnswer).length) return true;

    if (question.uiType === questionTypes.MULTIPLECHOICE_NESTED) {
      const differentBetweenCurrentAndOrigin = getDifferentProperties(
        currentAnswers, tidyUpOriginalAnswer,
      );
      const differentBetweenCurrentAndOriginTidy = Object.values(differentBetweenCurrentAndOrigin)
        .map((_nest) => {
          return _nest && _nest.nestedValues && (Object.values(_nest.nestedValues)[0]);
        });
      // no change, suppress modal
      if (differentBetweenCurrentAndOriginTidy.every(_.isEmpty)) { return false; }
      // as long as value changed, pop up modal
      if (differentBetweenCurrentAndOriginTidy.some((e) => e && e.value)) { return true; }

      // only unit change needs to look up
      // 1 get question unitType
      // 2 convert  unitType to uintLabel
      // 3 compare
      const reShapeDifference = Object.keys(differentBetweenCurrentAndOrigin).map((key) => {
        if (differentBetweenCurrentAndOrigin[key].nestedValues) {
          const unitMeasurementType = Object.values(differentBetweenCurrentAndOrigin[key].nestedValues)[0];
          return {
            nestQuestionId: key,
            rowQuestionId: differentBetweenCurrentAndOrigin[key].nestedValues ? Object.keys(differentBetweenCurrentAndOrigin[key].nestedValues)[0] : undefined,
            unitMeasurementType: unitMeasurementType !== undefined ? unitMeasurementType.unitMeasurementType : '',
          };
        }
        return {
          nestQuestionId: key,
          unitMeasurementType: differentBetweenCurrentAndOrigin[key].unitMeasurementType ? differentBetweenCurrentAndOrigin[key].unitMeasurementType : undefined,
        };
      }).filter((item) => item.unitMeasurementType);

      const differenceMapping = reShapeDifference.map((reShapeItem) => {
        const { unitType } = question.answers.filter((answer) => answer.id === reShapeItem.nestQuestionId)[0].nestedQuestions[0].rowQuestions.filter((row) => row.id === reShapeItem.rowQuestionId)[0];
        const unitMapping = getUnits(unitType);
        return {
          ...reShapeItem, unitType, unitMapping,
        };
      });

      // get lookUpOriginUnits for tidyUpOriginalAnswer
      const copyTidyUpOriginalAnswer = { ...tidyUpOriginalAnswer };
      const resultDifferenceUnit = differenceMapping.map((item) => {
        const oriUnitMeasurementType = copyTidyUpOriginalAnswer[item.nestQuestionId].nestedValues[item.rowQuestionId].unitMeasurementType;
        const originUnitTypeAndLabel = item.unitMapping.find((_item) => _item.unitLabel === oriUnitMeasurementType
          || _item.unitType === oriUnitMeasurementType);
        const currentUnitMeasurementType = currentAnswers[item.nestQuestionId].nestedValues[item.rowQuestionId].unitMeasurementType;
        // if current not one of unitType or unitLabel, pop up modal
        if ((originUnitTypeAndLabel) && (currentUnitMeasurementType !== originUnitTypeAndLabel.unitType) && (currentUnitMeasurementType !== originUnitTypeAndLabel.unitLabel)) { return true; }
        return false;
      });
      // as long as true in array pop up modal
      return resultDifferenceUnit.includes(true);
    }
    if (question.uiType === questionTypes.TEXTINPUTS) {
      if (_.isEqual(currentAnswers, tidyUpOriginalNestedAnswer)) return false;
      const differentBetweenCurrentAndOrigin = getDifferentProperties(currentAnswers, tidyUpOriginalNestedAnswer);
      const differentBetweenCurrentAndOriginTidy = Object.values(differentBetweenCurrentAndOrigin).map((_nest) => {
        return (_nest);
      });
      // if values, all undefined, just ignore warning modal. hot fixed
      if (differentBetweenCurrentAndOriginTidy.every((e) => e === undefined)) { return false; }
      // if  has value changed return true
      if (differentBetweenCurrentAndOriginTidy.some((e) => e && e.value)) { return true; }
      // if only unitMeasurementType change needs to 1 and 2
      const reShapeDifference = Object.keys(differentBetweenCurrentAndOrigin).map((key) => {
        return {
          rowQuestionId: key,
          unitMeasurementType: differentBetweenCurrentAndOrigin[key] && differentBetweenCurrentAndOrigin[key].unitMeasurementType,
        };
      }).filter((item) => item.unitMeasurementType);
      // 1 fetch uintType and convert to 2-digit,
      const differenceMapping = reShapeDifference.map((reShapeItem) => {
        const { unitType } = question.rowQuestions.find((answer) => answer.id === reShapeItem.rowQuestionId);
        const unitMapping = getUnits(unitType);
        return {
          ...reShapeItem, unitType, unitMapping,
        };
      });
      const copyTidyUpOriginalAnswer = { ...tidyUpOriginalNestedAnswer };
      const resultDifferenceUnit = differenceMapping.map((item) => {
        const oriUnitMeasurementType = copyTidyUpOriginalAnswer[item.rowQuestionId].unitMeasurementType;
        const originUnitTypeAndLabel = item.unitMapping.find((_item) => _item.unitLabel === oriUnitMeasurementType
          || _item.unitType === oriUnitMeasurementType);
        const currentUnitMeasurementType = currentAnswers[item.rowQuestionId].unitMeasurementType;
        // if current not one of unitType or unitLabel, pop up modal
        if ((originUnitTypeAndLabel) && (currentUnitMeasurementType !== originUnitTypeAndLabel.unitType) && (currentUnitMeasurementType !== originUnitTypeAndLabel.unitLabel)) { return true; }
        return false;
      });
      // 2 compare
      return resultDifferenceUnit.includes(true);// as long as true in array pop up modal
    }
  }
};

export default {
  isEmptyValueAndUnitForTextInput,
  isEmptyValueAndUnitForMultipleChoiceNest,
  isEmptyForMultiTypesAnswers,
  getDifferentProperties,
  isUpdateTrueAnswerOrNot,
};
