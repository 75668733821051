import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { GlobalContext } from '../../context/GlobalContext';
import Modal from '../../components/core/modal/Modal';
import Button from '../../components/core/button/Button';
import mediaService from '../../services/media/media-service';

import { get } from '../../lib/strings';

import './ProfileFacilities.scss';
import organisationService from '../../services/organisation/organisation-service';

const ProfileFacilities = ({ userOrganisations = [] }) => {
  const { updateOrg, user } = useContext(GlobalContext);
  const [openModal, updateOpenModal] = useState(false);
  const [facility, updateFacility] = useState({});
  const [isLoadingfacility, updateIsLoadingFacility] = useState(false);
  const [facilities, updateFacilities] = useState(userOrganisations);
  const facilitiesLength = facilities.length;
  const history = useHistory();

  const getPhoto = async (photoId) => {
    const result = await mediaService.getOrganisationPhoto(photoId);

    return result;
  };

  useEffect(() => {
    const getOrganisation = async () => {
      const orgs = [];
      facilities.map(async (item) => {
        let photo = '';

        if (item.image_id) {
          photo = await getPhoto(item.image_id);
        }

        orgs.push({
          ...item,
          image: photo,
        });

        if (facilitiesLength === orgs.length) {
          updateFacilities(orgs.sort(
            (a, b) => a.organisation_name.localeCompare(b.organisation_name),
          ));
        }
      });
    };

    getOrganisation();
  }, []);

  const onClickChangeFacility = async (newFacility) => {
    updateOpenModal(true);
    updateIsLoadingFacility(true);
    await organisationService.checkInvitedStatus(newFacility.organisation_id, user.id);
    updateFacility(newFacility);
    updateIsLoadingFacility(false);
  };

  const onClickConfirm = () => {
    updateOrg(facility);
    updateOpenModal(false);

    history.push('/');
  };

  return (
    <div className="component__profile-facilities">
      <div className="gallery-grid">
        {facilities.map((item, index) => {
          return (
            <div key={item.organisation_id} className="gallery-frame" onClick={() => onClickChangeFacility(item)} tabIndex={index} aria-hidden="true" role="button" aria-label="Set facility">
              <img className="gallery-img" src={item.image} key={item.image} alt="facility logo" />
              <p className="gallery-image-title">{item.organisation_name}</p>
              <div className="gallery-image-gradient" />
            </div>
          );
        })}
      </div>
      <Modal
        show={openModal}
        onClose={() => updateOpenModal(false)}
        heading={get('profile-facility-change-modal-title')}
        bodyText={isLoadingfacility ? (
          <div className="button__loading-container">
            <div className="button__loading-wrapper">
              <svg className="button__loading-spinner" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
              </svg>
            </div>
          </div>
        ) : (<p>{`${get('profile-facility-change-modal-description')} ${facility.organisation_name}`}</p>)}
        footer={isLoadingfacility ? null : (
          <div className="profile-facility-change-modal-footer">
            <Button className="profile-facility-change-modal-button" onClick={onClickConfirm}>
              Confirm
            </Button>
          </div>
        )}
      />
    </div>
  );
};

ProfileFacilities.propTypes = {
  userOrganisations: PropTypes.arrayOf({
    organisation_name: PropTypes.string,
  }).isRequired,
};

export default ProfileFacilities;
