import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './LinearProgressCard.scss';
import { get } from '../../../../lib/strings';
import InternalLinkButton from '../../link/InternalLinkButton';
import reviewImg from '../../../../assets/Review.svg';

import LinearProgressBar from './LinearProgressBar';
import mediaService from '../../../../services/media/media-service';

const LinearProgressCard = ({
  title, percentage, colour, href, icon,
}) => {
  const [fetchedIcon, updateIcon] = useState('');
  useEffect(() => {
    const getIcon = async () => {
      const logo = await mediaService.getReviewIcon(icon);
      updateIcon(logo);
    };

    if (icon) {
      getIcon();
    } else {
      updateIcon(reviewImg);
    }
  }, []);
  return (

    <div className="liner-progress-card " style={{ color: colour }}>
      <div className="liner-progresss-card___img-wrapper">
        <img src={fetchedIcon} alt={`${title}`} />
      </div>
      <div className="linear-progress-card-meta">
        <div className="linear-progress-card-meta-progress">
          <h5 className="linear-progress-title" color={{ color: colour }}>{title}</h5>
          <LinearProgressBar colour={colour} percentage={percentage} />
        </div>
      </div>
      <InternalLinkButton
        to={href}
        size="large"
        className="btn__basic linear-progress-link"
        style={{ background: colour }}
        colour={colour}
      >

        {percentage === 100 ? get('bestpractices-button-edit') : get('bestpractices-button-continue')}
      </InternalLinkButton>
    </div>
  );
};

LinearProgressCard.propTypes = {
  title: PropTypes.string.isRequired,
  percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  colour: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};
export default LinearProgressCard;
