import { useContext } from 'react';
import DOMPurify from 'dompurify';
import getCodeFromQueryString from './queryStringGetter';
// eslint-disable-next-line import/no-cycle
import { GlobalContext } from '../context/GlobalContext';

DOMPurify.setConfig({
  ADD_ATTR: ['target'], // Allow elements with the target attribute
});

export function get(id) {
  const { strings = {}, user } = useContext(GlobalContext);
  const { defaultStrings, strings: languageStrings } = strings;
  const langFromQuery = getCodeFromQueryString();
  let language;

  if (user && user.language) {
    language = user.language;
  } else if (langFromQuery) {
    language = langFromQuery;
  } else {
    language = 'en';
  }

  if (!languageStrings) {
    return '<no string>';
  }

  // Get string for user selected language
  if (languageStrings[id] && languageStrings[id][language]) {
    return languageStrings[id][language];
  }

  // Fall back used if some translation is missing (return en string)
  if (defaultStrings[id] && defaultStrings[id].en) {
    return defaultStrings[id].en;
  }

  return `<${id}>`;
}

export function stringSanitizer(string) {
  const sanitizer = DOMPurify.sanitize;

  if (!string) return null;

  return sanitizer(string);
}

export function getStringArray(string = '', delimiters = '|') {
  const strings = string.split(delimiters);
  const newStrings = [];

  // eslint-disable-next-line array-callback-return
  strings.map((item) => {
    newStrings.push({
      value: item,
      key: item,
    });
  });

  return newStrings;
}
