import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import HttpStatus from 'http-status-codes';
import format from 'date-fns/format';
import Form from 'react-bootstrap/Form';
import { get } from '../../../lib/strings';
import '../DocumentLibrary.scss';
import Button from '../../../components/core/button/Button';
import DropdownField from '../../../components/core/form/DropdownField';
import FormError from '../../../components/core/form/FormError';
import { GlobalContext } from '../../../context/GlobalContext';
import organisationService from '../../../services/organisation/organisation-service';
import Loader from '../../../components/Loaders/PageLoader';
import Modal from '../../../components/core/modal/Modal';
import TextInputField from '../../../components/core/form/TextInputField';
import Alert from '../../../components/core/alert/Alert';
import reviewsService from '../../../services/reviews/reviews-service';
import documentsService from '../../../services/documents/documents-service';
import contentService from '../../../services/content/content-service';
import { DocumentLibrarySubsectionMapping, DocumentLibraryUploadLocations } from '../../../lib/consts/documentLibraryLocations';
import themeType from '../../../lib/consts/themeType';
import IconEditImg from '../../../assets/icon-edit.svg';
import IconTrashImg from '../../../assets/icon-trash.svg';

const DocumentLibraryYourLocationDocuments = () => {
  const {
    org, user, reviewLinks, theme,
  } = useContext(GlobalContext);
  const [uploadDocumentModal, updateUploadDocumentModal] = useState(false);
  const [modalError, setModalError] = useState(undefined);
  const [isPageLoading, updateIsPageLoading] = useState(false);
  const [documents, setDocuments] = useState(null);
  const [selectedSection, setSelectedSection] = useState(false);
  const [librarySections, setLibrarySections] = useState(null);
  const [questionSetData, setQuestionSetData] = useState(null);
  const [sectionName, setSectionName] = useState(null);
  const [fileModal, setFileModal] = useState(false);
  const [documentDetails, setDocumentDetails] = useState({});

  const organisationId = org.organisation_id;

  const {
    register, handleSubmit, errors, control, getValues, setValue, clearErrors,
  } = useForm();
  const uploadRef = useRef(null);

  const { section } = useParams();

  const uploadDocumentError = get('documentLibrary-uploadDocumentError');
  const requiredFieldError = get('login-formRequiredField');
  const fromRequiredFieldError = get('documentLibrary-formErrorRequiredField');

  const getDocuments = async (sections) => {
    const result = await organisationService.getDocuments(organisationId);

    if (result.ok) {
      if (sections) {
        const thisSection = sections
          .find((aSection) => aSection.slug.endsWith(section));

        if (thisSection) {
          const filtered = result.data.filter((item) => {
            return thisSection.filterKeys.includes(item.location);
          });
          setDocuments(filtered);
        }
      } else {
        setDocuments(result.data);
      }
    }
  };

  const getSections = async () => {
    const sections = [];
    const questionSets = {};
    const { bestPractices } = reviewLinks;
    const isTournament = theme === themeType.TOURNAMENT;

    if (isTournament) {
      const tournamentSubsections = DocumentLibrarySubsectionMapping.TOURNAMENTS;
      for (let i = 0; i < tournamentSubsections.length; i += 1) {
        sections.push({
          ...tournamentSubsections[i],
        });
      }
    } else {
      if (!bestPractices) {
        return;
      }

      /* eslint-disable no-await-in-loop */
      for (let i = 0; i < bestPractices.length; i += 1) {
        const reviewLink = bestPractices[i];
        const questionSet = await reviewsService.getOrganisationQuestionsById(
          reviewLink.id,
          org.organisation_id,
          user.language,
        );
        questionSets[questionSet.id] = questionSet;

        sections.push({
          key: questionSet.id,
          labelString: reviewLink.title,
          slug: `document-library/your/${reviewLink.slug}`,
          color: reviewLink.colour,
          filterKeys: [questionSet.id, ...questionSet.sections.map((item) => item.id)],
          icon: reviewLink.icon,
        });
      }
    }

    sections.push({
      ...DocumentLibrarySubsectionMapping.OTHER,
    });

    const thisSection = sections.find((aSection) => {
      return aSection.slug.endsWith(section);
    });
    setSectionName(thisSection && thisSection.labelString);
    setQuestionSetData(questionSets);
    setLibrarySections(sections);
    getDocuments(sections);
  };

  const openMoveFileModal = (document, currentLocation) => {
    setModalError(undefined);
    setFileModal(true);
    const updateDocument = {
      ...document,
      currentLocation,
    };
    setDocumentDetails(updateDocument);
  };

  useEffect(() => {
    if (librarySections === null) {
      getSections();
    }
  }, []);

  useEffect(() => {
    if (fileModal) {
      setValue('filename', documentDetails.blob_filename);
    }
  }, [fileModal]);

  const deleteDocument = async (documentId) => {
    const result = await organisationService.deleteDocument(organisationId, documentId);

    if (result.ok) {
      getDocuments(librarySections);
    }
  };

  const downloadDocument = async (documentId) => {
    return contentService.getDownloadDocumentByDocId(documentId);
  };

  const onClickModalUpdateDocument = async (data) => {
    setFileModal(false);
    updateIsPageLoading(true);

    const docData = {
      documents: data,
    };
    const result = await organisationService.updateDocument(
      organisationId, documentDetails.id, docData,
    );

    if (result.ok) {
      getDocuments();
    } else if (result.status === HttpStatus.UNAUTHORIZED) {
      fileModal(true);// if got response error, will re-launch modal
      setModalError(uploadDocumentError);
    } else {
      throw modalError;
    }
    updateIsPageLoading(false);
  };

  const onClickUpload = (sectionKey) => {
    setSelectedSection(sectionKey);
    setModalError(undefined);
    updateUploadDocumentModal(true);
  };

  const onClickModalUploadDocument = async (data) => {
    updateUploadDocumentModal(false);
    updateIsPageLoading(true);

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    const formData = new FormData();
    formData.append('file', getValues('uploadFile'));
    formData.append('name', data.name);
    formData.append('section', selectedSection);

    const result = await organisationService.uploadDocument(organisationId, formData, config);

    if (result.ok) {
      getDocuments(librarySections);
    } else if (result.status === HttpStatus.UNAUTHORIZED) {
      updateUploadDocumentModal(true);
      setModalError(uploadDocumentError);
    } else {
      throw modalError;
    }
    updateIsPageLoading(false);
  };

  const onClickSelectFile = () => {
    uploadRef.current.click();
  };

  const selectedFile = getValues('uploadFile');

  return (
    <div className={`${theme === themeType.TOURNAMENT ? 'img-tour page' : 'img-facility page'}`}>
      <div className="page-header">
        <div className="document-library-page-header">
          <div>
            <h4 className="--dark">
              {get('documentLibrary-sectionTitle')}
            </h4>
            <h3 className="--dark">
              <div>
                {sectionName}
              </div>
            </h3>
          </div>
        </div>
        <div>
          <Loader isLoading={isPageLoading}>
            <div className="component__document-library">
              {librarySections && librarySections
                .find((aSection) => aSection.slug.endsWith(section))
                .filterKeys
                .map((sectionKey) => {
                  const filteredDocuments = documents && documents.filter((doc) => {
                    return doc.location === sectionKey;
                  });
                  const sectionLabel = documentsService
                    .locationStringForId(sectionKey, questionSetData);
                  return (
                    <div className="document-library-your-documents-latest-card">
                      <div className="document-library-your-documents-latest-header">
                        <p className="document-library-your-documents-latest-header-title">{typeof sectionLabel === 'function' ? sectionLabel() : sectionLabel}</p>
                        <Button type="submit" className="document-library-upload-button medium primary" onClick={() => onClickUpload(sectionKey)}>
                          {get('documentLibrary-upload')}
                        </Button>
                      </div>
                      <table>
                        <thead>
                          <tr>
                            <th scope="col">{get('documentLibrary-documentTable-filename')}</th>
                            <th scope="col">{get('documentLibrary-documentTable-location')}</th>
                            <th scope="col">{get('documentLibrary-documentTable-description')}</th>
                            <th scope="col">{get('documentLibrary-documentTable-date')}</th>
                            <th scope="col" aria-label="cta" />
                          </tr>
                        </thead>
                        <tbody>
                          {
                            filteredDocuments && filteredDocuments.map((document) => {
                              const currentLocation = documentsService.locationStringForId(
                                document.location,
                                questionSetData,
                                librarySections,
                              );
                              return (
                                <tr key={document.id}>
                                  <td
                                    data-label={get('documentLibrary-documentTable-filename')}
                                    className="document-library-your-documents-latest-download-button --blue"
                                    data-cy="document-library-delete-document-button"
                                    onClick={() => downloadDocument(document.id)}
                                    aria-hidden="true"
                                  >
                                    {document.blob_filename}
                                  </td>
                                  <td data-label={get('documentLibrary-documentTable-location')}>
                                    {
                                      documentsService.locationStringForId(
                                        document.location,
                                        questionSetData,
                                        librarySections,
                                      )
                                    }
                                  </td>
                                  <td data-label={get('documentLibrary-documentTable-description')}>{document.blob_filename !== document.title ? document.title : ''}</td>
                                  <td data-label={get('documentLibrary-documentTable-date')}>
                                    {format(new Date(document.created_at), 'dd MMMM yyyy')}
                                  </td>
                                  <td>{
                                  document.oncourse_document ? null : (
                                    <div className="document-library-process">
                                      <button
                                        id="edit"
                                        type="button"
                                        onClick={() => openMoveFileModal(
                                          document, currentLocation,
                                        )}
                                      >
                                        <img src={IconEditImg} alt="edit answer" />
                                      </button>
                                      <div className="document-library-process-separator" />
                                      <button
                                        id="delete"
                                        type="button"
                                        data-cy="document-library-delete-document-button"
                                        onClick={() => deleteDocument(document.id)}
                                      >
                                        <img src={IconTrashImg} alt="trash answer" />
                                      </button>
                                    </div>
                                  )
                                }
                                  </td>
                                </tr>
                              );
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  );
                })}
            </div>
          </Loader>
          <Modal
            show={uploadDocumentModal}
            onClose={() => updateUploadDocumentModal(false)}
            heading={get('documentLibrary-uploadDocumentModalTitle')}
            bodyText={(
              <div>
                <Form>
                  <div className="document-library-upload-modal">
                    <Controller
                      name="uploadFile"
                      control={control}
                      rules={{
                        validate: (value) => {
                          if (typeof value === 'undefined' || value.length === 0) {
                            return requiredFieldError;
                          }

                          return true;
                        },
                      }}
                      render={() => (
                        <>
                          <input
                            type="file"
                            onChange={(e) => {
                              setValue('uploadFile', e.target.files[0]);
                              clearErrors('uploadFile');
                            }}
                            ref={uploadRef}
                            hidden
                          />
                        </>
                      )}
                    />
                    <div className="pb-4">
                      <Button className="upload-button document-library-upload-button" onClick={onClickSelectFile} aria-hidden="true">
                        <p className="upload-button-text">{get('documentLibrary-selectFile')}</p>
                      </Button>
                      {errors && errors.uploadFile && errors.uploadFile.message ? (
                        <FormError message={errors.uploadFile.message} />
                      ) : null}
                    </div>
                    {selectedFile ? (
                      <div className="document-library-your-documents-latest-selected-file">
                        <p className="col-9">{selectedFile.name}</p>
                        <Button
                          className="col-3 document-library-your-documents-latest-delete-button --red"
                          onClick={
                            () => {
                              setValue('uploadFile', '');
                              uploadRef.current.value = null;
                              clearErrors('uploadFile');
                            }
                          }
                        >
                          {get('documentLibrary-unselectFile')}
                        </Button>
                      </div>
                    ) : null}
                    <p><span className="document-library-upload-modal-note">{get('documentLibrary-Note')}</span>{get('documentLibrary-storageSectionDescription')}: {
                      selectedSection
                      && (
                        documentsService.locationStringForId(
                          selectedSection, questionSetData, librarySections,
                        ))
                      }
                    </p>
                    <TextInputField
                      type="text"
                      name="name"
                      label={get('documentLibrary-uploadDocumentModalFormTitleLabel')}
                      placeholder={get('documentLibrary-uploadDocumentModalFormTitlePlaceholder')}
                      className="input-field-row"
                      data-cy="upload-document-title"
                      controlled
                      ref={register({
                        required: true,
                      })}
                      errors={errors}
                    />
                  </div>
                </Form>
              </div>
            )}
            footer={(
              <div className="document-library-upload-modal-footer">
                {modalError && (
                  <Alert data-cy="registration-error" className="document-library-upload-modal-alert" variant="danger">
                    {modalError}
                  </Alert>
                )}
                <Button className="document-library-upload-modal-button" onClick={handleSubmit(onClickModalUploadDocument)}>
                  {get('documentLibrary-uploadDocumentModalFormButton')}
                </Button>
              </div>

            )}
          />
          <Modal
            show={fileModal}
            enforceFocus={false}
            onClose={() => setFileModal(false)}
            heading={get('documentLibrary-moveDocumentModalFormHead')}
            subtitle={`${get('documentLibrary-moveDocumentModalFormSubTitle')} ${documentDetails.currentLocation}`}
            bodyText={(
              <div>
                <Form>
                  <div className="document-library-upload-modal">
                    <DropdownField
                      label={get('documentLibrary-uploadDocumentModalFormSectionDropdownLabel')}
                      control={control}
                      name="location"
                      options={
                    [DocumentLibraryUploadLocations.otherLocations]
                      .concat(
                        (librarySections && librarySections.flatMap((aSection) => {
                          const options = aSection.filterKeys.map((key) => {
                            return {
                              key,
                              value: documentsService.locationStringForId(
                                key, questionSetData, librarySections,
                              ),
                            };
                          });
                          return options;
                        })
                        ),
                      ).filter((item) => !Array.isArray(item)).filter((n) => n)
                  }
                      className="input-field-row"
                      validationRules={(value) => {
                        if (typeof value !== 'undefined' && librarySections.find((bSection) => {
                          return bSection.filterKeys.includes(value);
                        })) {
                          return true;
                        }
                        return fromRequiredFieldError;
                      }}
                      errors={errors}
                    />
                    <TextInputField
                      type="text"
                      name="filename"
                      label={get('documentLibrary-documentTable-filename')}
                      className="input-field-row"
                      data-cy="upload-document-title"
                      controlled
                      ref={register({
                        required: true,
                      })}
                      errors={errors}
                    />

                  </div>
                </Form>
              </div>
        )}
            footer={(
              <div className="delete-notes-ctas">
                <Button
                  className="flex-3"
                  form="add-editor-form"
                  type="button"
                  inverse
                  onClick={() => setFileModal(false)}
                >
                  {get('question-deleteNotesCancelButton')}
                </Button>
                <Button
                  className="primary flex-1"
                  form="add-editor-form"
                  type="button"
                  onClick={handleSubmit(onClickModalUpdateDocument)}
                >
                  {get('question-deleteNotesConfirmButton')}
                </Button>
              </div>
          )}
          />
        </div>
      </div>
    </div>
  );
};

export default DocumentLibraryYourLocationDocuments;
