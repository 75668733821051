/* eslint-disable no-unused-vars */

import arrayUtils from '../utils/array';

export const interpretColour = (colour) => {
  switch (colour) {
    case 'blue':
      return 'secondary';
    case 'orange':
      return 'tertiary';
    case 'yellow':
      return 'yellow';
    default:
      return 'primary';
  }
};

export const defaultUnits = (userUnits) => {
  switch (userUnits) {
    case 'british imperial': {
      return {
        AREA: 'ft²',
        AREA_OUTDOOR: 'ac',
        CURRENCY: '£',
        DISTANCE: 'yd',
      };
    }
    case 'united states customary': {
      return {
        AREA: 'ft²',
        AREA_OUTDOOR: 'ac',
        CURRENCY: '$',
        DISTANCE: 'yd',
      };
    }
    default:
      return {
        AREA: 'm²',
        AREA_OUTDOOR: 'ha',
        CURRENCY: '€',
        DISTANCE: 'm',
      };
  }
};

export const defaultUserUnits = (userUnits) => {
  const lowerCaseUserUnits = userUnits ? userUnits.toLowerCase() : userUnits;
  switch (lowerCaseUserUnits) {
    case 'british imperial': {
      return {
        Area: 'ac',
        'Volume (Water)': 'Acre-feet',
        Mass: 'lbs',
        Currency: '£',
        'Flow Rate': 'UK gal/ac',
        Electricity: 'kWh',
        'Mass Per Area': 'kg / ac',
        'Micro Flow Rate': 'UKfloz/yd2',
        Volume: 'gal',
        'Volume (Gas)': 'ft³',
        Length: 'yd',
        'Mass Per Volume (Water)': 'lbs / gal',
        'Area Square': 'ft²',
        'Mass (tonnes)': 'ton',
        'Tonnes of CO2e': 'tCO2e',
        Distance: 'mi',
      };
    }
    case 'united states customary':
    case 'unitedstatescustomary':
      return {
        Area: 'ac',
        'Volume (Water)': 'Acre-feet',
        Mass: 'lbs',
        Currency: '$',
        'Flow Rate': 'US gal/ac',
        Electricity: 'kWh',
        'Mass Per Area': 'lb/1000ft2',
        'Micro Flow Rate': 'USfloz/ft2',
        Volume: 'gal',
        'Volume (Gas)': 'ft³',
        Length: 'yd',
        'Mass Per Volume (Water)': 'lbs / gal',
        'Area Square': 'ft²',
        'Mass (tonnes)': 'ton',
        'Tonnes of CO2e': 'tCO2e',
        Distance: 'mi',
      };
    // add this type, in order to return undefine
    case 'metric': {
      return {
        Area: 'ha',
        'Volume (Water)': 'm³',
        Mass: 'kg',
        Currency: '€',
        'Flow Rate': 'L / ha',
        Electricity: 'kWh',
        'Mass Per Area': 'kg / ha',
        'Micro Flow Rate': 'mL / m²',
        Volume: 'l',
        'Volume (Gas)': 'm³',
        Length: 'm',
        'Mass Per Volume (Water)': 'g / l',
        'Area Square': 'm²',
        'Mass (tonnes)': 'tonnes',
        'Tonnes of CO2e': 'tCO2e',
        Distance: 'km',
      };
    }
    default: // return undefine
  }
};

export const getUnits = (unitType) => {
  const metric = defaultUserUnits('metric');
  const usa = defaultUserUnits('united states customary');
  const british = defaultUserUnits('british imperial');

  const units = [{
    unitType: 'metric',
    unitLabel: metric[unitType],
  }, {
    unitType: 'united states customary',
    unitLabel: usa[unitType],
  }, {
    unitType: 'british imperial',
    unitLabel: british[unitType],
  }];

  return units;
};

export const getUnitFromPayloadOrFromUtils = (answerFromData) => {
  return defaultUserUnits(answerFromData.unitMeasurementType)
    ? defaultUserUnits(answerFromData.unitMeasurementType)
    : answerFromData.unitMeasurementType;
  // return unit unitMeasurementType self, because it's already 2 digital unit
};
