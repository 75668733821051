import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import './FacilityCardTile.scss';
import mediaService from '../../../../services/media/media-service';
import { get } from '../../../../lib/strings';
import Permissions from '../../../../lib/permissions';
import PermissionsGated from '../../../context-aware/PermissionsGated/PermissionsGated';
import noImage from '../../../../assets/no-image.svg';

import Button from '../../button/Button';
import { GlobalContext } from '../../../../context/GlobalContext';
import themeType from '../../../../lib/consts/themeType';

const FacilityCardTile = ({
  isOrganisationCertified,
  orgTitle,
  userOrganisationsLength,
  profileLink,
  facilityProfileLink,
  ProfileTabIndexe,
  logoId,
  profileId,
}) => {
  const history = useHistory();
  const [logo, updateLogo] = useState(noImage);
  const [profile, updateProfile] = useState(noImage);
  const {
    theme,
  } = useContext(GlobalContext);

  const getPhoto = async (id, callback) => {
    if (id) {
      const result = await mediaService.getOrganisationPhoto(id);

      if (result) {
        callback(result);
      }
    }
  };

  useEffect(() => {
    getPhoto(profileId, (photo) => {
      updateProfile(photo);
    });
  }, [profileId]);

  useEffect(() => {
    getPhoto(logoId, (photo) => {
      updateLogo(photo);
    });
  }, [logoId]);

  return (
    <div className="dashboard-facility-card">
      <div
        className="dashboard-facility-card-profile-image"
        style={{
          backgroundImage: `url(${profile})`,
        }}
      >
        <div
          className={`dashboard-facility--logo-image ${logoId ? '' : '--shadow'}`}
          style={{
            backgroundImage: `url(${logo})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        />
      </div>
      <div className="dashboard-facility-card-name">
        <div className="dashboard-facility-card-name-wrapper">
          {isOrganisationCertified ? <p className="dashboard-facility-card-name-certified">{get('dashboard-certified')}</p> : null}
          <p className="dashboard-facility-card-name-text">{orgTitle}</p>
          {userOrganisationsLength > 1 ? (
            <div
              className="dashboard-facility-card-name-switch"
              onClick={() => history.push(profileLink,
                { selectedTab: ProfileTabIndexe })}
              aria-hidden
            >
              {get('dashboard-switchClub')}
            </div>
          ) : null}
        </div>
        <PermissionsGated activities={[Permissions.activities.FACILITY_PROFILE]}>
          <div className="dashboard-facility-card-button-wrapper">
            <Button
              inverse
              colour={theme === themeType.TOURNAMENT ? '#1077C6' : '#4CA471'}
              className="dashboard-facility-card-name-button"
              onClick={() => history.push(facilityProfileLink)}
            >
              {get('dashboard-facility-profile-button')}
            </Button>
          </div>
        </PermissionsGated>

      </div>
    </div>
  );
};
FacilityCardTile.defaultProps = {
  orgTitle: '',
  isOrganisationCertified: false,
  logoId: '',
  profileId: '',
};

FacilityCardTile.propTypes = {
  logoId: PropTypes.number,
  profileId: PropTypes.number,
  isOrganisationCertified: PropTypes.bool,
  orgTitle: PropTypes.string,
  userOrganisationsLength: PropTypes.number.isRequired,
  profileLink: PropTypes.string.isRequired,
  facilityProfileLink: PropTypes.string.isRequired,
  ProfileTabIndexe: PropTypes.number.isRequired,
};

export default FacilityCardTile;
